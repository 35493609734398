@import './_focus.pcss';

@mixin overlay-button($top, $right) {
  position: absolute;
  background-color: var(--lia-bs-white);
  box-shadow: var(--lia-box-shadow-xs);
  border-radius: var(--lia-bs-border-radius-sm);
  top: $top;
  right: $right;
  z-index: 2;
  border: 0;
  padding: 0;
  width: 36px;
  height: 36px;

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    background-color: var(--lia-bs-gray-200);
    box-shadow: var(--lia-box-shadow-xs);
  }
}

/**
 * Goes on the visible element.
 * Must come before drag-button-outer if being used on the same element
 */
@mixin drag-button-visible() {
  background-color: var(--lia-bs-gray-200);
  border: 1px solid var(--lia-bs-white);
  border-radius: var(--lia-bs-border-radius-sm);
  box-shadow: var(--lia-bs-box-shadow-sm);
  display: flex;
  height: 100%;
  width: 100%;

  &:hover,
  &:focus {
    background-color: var(--lia-bs-white);
  }
}

/**
 * Goes on the container that receives `draggableProps`
 * Must come after drag-button-visible if being used on the same element
 */
@mixin drag-button-outer($display: flex, $height: 50px, $marginBottom: 5px) {
  align-items: center;
  display: $display;
  font-size: var(--lia-bs-font-size-sm);
  margin-bottom: $marginBottom;
  height: $height;

  > svg {
    margin: 0 10px;
  }
}

/**
 * Goes on the button used as a drag handle
 */
@mixin drag-button-handle() {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px 0 10px;
  border-radius: var(--lia-bs-border-radius-sm) 0 0 var(--lia-bs-border-radius-sm);
  cursor: grab !important;
  @include default-focus-box-shadow();
}

/**
 * Goes on the button which displays the label
 */
@mixin drag-button-content() {
  flex: 1;
  height: 100%;
  padding: 0 15px 0 5px;
  overflow: hidden;
  border-radius: 0 var(--lia-bs-border-radius-sm) var(--lia-bs-border-radius-sm) 0;
  @include default-focus-box-shadow();
}

/**
 * Goes on the label itself
 */
@mixin drag-button-title() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
