/**
 * Default outline styles.
 */
/**
 * Default input-style box-shadow.
 */
/**
 * Default focus-visible outline styles
 */
/**
 * Default focus-visible styles for button
 * or button-like elements.
 */
/**
 * Goes on the visible element.
 * Must come before drag-button-outer if being used on the same element
 */
/**
 * Goes on the container that receives `draggableProps`
 * Must come after drag-button-visible if being used on the same element
 */
/**
 * Goes on the button used as a drag handle
 */
/**
 * Goes on the button which displays the label
 */
/**
 * Goes on the label itself
 */
.EditFeaturedList_lia-draggable__WYlHx {
  background-color: var(--lia-bs-gray-200);
  border: 1px solid var(--lia-bs-white);
  border-radius: var(--lia-bs-border-radius-sm);
  box-shadow: var(--lia-bs-box-shadow-sm);
  display: flex;
  height: 100%;
  width: 100%;
}
.EditFeaturedList_lia-draggable__WYlHx:hover,
  .EditFeaturedList_lia-draggable__WYlHx:focus {
    background-color: var(--lia-bs-white);
  }
.EditFeaturedList_lia-draggable__WYlHx {
  align-items: center;
  display: ;
  font-size: var(--lia-bs-font-size-sm);
  margin-bottom: 0.3125rem;
  height: 3.125rem;
}
.EditFeaturedList_lia-draggable__WYlHx > svg {
    margin: 0 0.625rem;
  }
.EditFeaturedList_lia-draggable__WYlHx {
  box-shadow: 0 2px 4px
    hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.06);
}
/* end users may have removed box shadows via theming but we want it on the draggable here */
.EditFeaturedList_lia-draggable-dragging__WLoGP {
    box-shadow: 0 10px 30px
      hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.15);
    z-index: 1;
  }
/* show close icon on hover or when a child element is receiving focus */
.EditFeaturedList_lia-draggable__WYlHx:focus-within,
  .EditFeaturedList_lia-draggable__WYlHx:hover {
    background-color: var(--lia-bs-gray-200);
  }
.EditFeaturedList_lia-draggable__WYlHx:focus-within .EditFeaturedList_lia-close-icon__Hy1Fe, .EditFeaturedList_lia-draggable__WYlHx:hover .EditFeaturedList_lia-close-icon__Hy1Fe {
      opacity: 1;
    }
.EditFeaturedList_lia-drag-handle__N0j6A {
  display: flex;
  flex: 1;
  column-gap: 0.3125rem;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.3125rem 0 0.625rem;
  border-radius: var(--lia-bs-border-radius-sm) 0 0 var(--lia-bs-border-radius-sm);
  cursor: grab !important;
}
.EditFeaturedList_lia-drag-handle__N0j6A:focus {
    outline: unset;
    box-shadow: unset;
  }
.EditFeaturedList_lia-drag-handle__N0j6A:focus-visible {
  box-shadow: var(--lia-bs-input-focus-box-shadow), 0 0 0 1px var(--lia-bs-input-focus-border-color);
  outline: unset;
  }
.EditFeaturedList_lia-drag-handle-icon__e_xT_ {
    flex-shrink: 0;
  }
.EditFeaturedList_lia-placeholder__wuDoe {
  margin-left: -0.0625rem;
}
.EditFeaturedList_lia-close-icon__Hy1Fe {
  transform: opacity var(--lia-timing-fast) var(--lia-timing-function);
  opacity: 0;
}

