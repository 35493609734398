@import '@aurora/shared-client/styles/mixins/_buttons.pcss';

.lia-draggable {
  @include drag-button-visible();
  @include drag-button-outer();
  /* end users may have removed box shadows via theming but we want it on the draggable here */
  box-shadow: 0 2px 4px
    hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.06);
  &-dragging {
    box-shadow: 0 10px 30px
      hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.15);
    z-index: 1;
  }
  /* show close icon on hover or when a child element is receiving focus */
  &:focus-within,
  &:hover {
    background-color: var(--lia-bs-gray-200);

    .lia-close-icon {
      opacity: 1;
    }
  }
}

.lia-drag-handle {
  display: flex;
  flex: 1;
  column-gap: 5px;
  @include drag-button-handle();

  &-icon {
    flex-shrink: 0;
  }
}

.lia-placeholder {
  margin-left: -1px;
}

.lia-close-icon {
  transform: opacity var(--lia-timing-fast) var(--lia-timing-function);
  opacity: 0;
}
